
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$maxscholar-noauth-primary: mat.define-palette(mat.$indigo-palette);
$maxscholar-noauth-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$maxscholar-noauth-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$maxscholar-noauth-theme: mat.define-light-theme((
  color: (
    primary: $maxscholar-noauth-primary,
    accent: $maxscholar-noauth-accent,
    warn: $maxscholar-noauth-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($maxscholar-noauth-theme);

@import url(_variables.scss);

@font-face {
    font-family: "Lato";
    src: url("assets/fonts/Lato/Lato-Regular.ttf");
}
@font-face {
    font-family: "Lato";
    src: url("assets/fonts/Lato/Lato-Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/Montserrat/Montserrat-Bold.ttf");
    font-weight: bold;
}

body {
    font-family: "Lato", sans-serif;
    color:#333;
}
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

//HEADERS
h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 57px;
}
h3.subtitle {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 112%;
    text-transform: uppercase;
    color: #128E8E;
}

//BUTTONS
// there should be a single "button" class but well...
.orange-button, .orange-inverse-button, .red-button, .green-button, .white-button {
    background: var(--orange);
    color:white;
    border:none;
    border-radius:10px;
    padding:9px;
    text-align: center;

    img {
        margin-right:5px;
    }

    &:hover:not(.orange-inverse-button) {
        color:white;
        text-decoration: none;
    }
    &[disabled] {
        opacity:0.5;
    }
}
.orange-inverse-button {
    background: transparent;
    color:var(--orange);
    border:1px solid var(--orange);
}
.red-button {
    background-color:crimson;
}
.green-button {
    background-color: var(--maxscholar-green);
    color:white;
}
.white-button {
    background-color: white;
    box-shadow: 0px 0px 5px #333;
    min-width: 4em;
    color:#333;
}

//NAVBAR
#navbar, .content {
    max-width:1146px;
    margin:0 auto;
}
navbar {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
}
.first-section {
    padding-top:120px;

    p {
        max-width: 700px;
        margin:10px auto;
    }
}
.left-aligned-header {
    p {
        margin: 1em;
        margin-left: 0;
    }
}

.footer-mobile-menu {
  text-align: center;
  margin: 0;
}

.footer-mobile-menu > button {
  margin-left: 1em;
}

.footer-mobile-menu > a {
  margin: 0 !important;
  display: inline !important;
}

#footer-navigation {
    background: #DCF0F0;
    height: 160px;

    navbar {
        position:static;
    }

    #navbar {
        padding-top:32px;
        padding-left:1em;
        .section-link {
            position:relative;
            top: 60px;
            left: -254px;
        }
        .support-link {
            display:none;
        }
        .login-button {
            margin-right:1em;
        }
        .dashboard-button {
          background: #f89f4b;
          margin-left:1em;
        }
    }

    #navbar.lightBgr {
        color:var(--greenblue);

        .webinar-button {
            color:var(--greenblue);
            border-color:var(--greenblue);
        }
        .logo-black {
            opacity:1;
        }
        .cart-icon {
            .fill-color {
                fill: var(--greenblue);
            }
        }
    }

    .mobile-menu-open {
        display: none !important;
    }
}


// HOME
//CAROUSEL OVERIDES
.carousel-indicators li {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    opacity: 1 !important;
    margin-left:17px;
    margin-right:17px;
}
.carousel-indicators li.active {
    background-color: transparent;
    background: url(/assets/img/pacman.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 18px;
    height: 12px;
}
.carousel-control-next, .carousel-control-prev {
    display:none !important;
}

// FORMS
.mat-form-field-infix {
    width:auto !important;
}

// GENERAL
img {
    max-width:100%;
}
.center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
.bgr-shapes {
    background: url("/assets/img/bgr-shapes-2.png");
    background-size: 100% auto;
    background-position: center 40px;
    background-repeat: no-repeat;
}
.mat-dialog-actions {
    justify-content: space-around;
}
.orange-bullet-list {
    list-style: none;

    li {
        margin-bottom: 2em;
    }

    li::before {
        content: "\2022";
        color: orange;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
}



// OVERALL RESPONSIVE

@media (max-width: 1146px) {
    .first-section .content {
        padding: 1em;
    }
    navbar {
        padding-left:1em;
    }
}

@media (max-width: 950px) {

    .content {
        padding:1em;
    }
    h1 {
        font-size:42px;
    }
}
